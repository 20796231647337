(function () {
  'use strict';

  ScrollReveal().reveal('.kv-bg .container', {
    distance: '100px',
    origin: 'bottom',
    duration: 800,
    easing: 'ease-in-out',
  });

  $('.toggler').on('click', function (e) {
    $('.header').toggleClass('menu-open');
  });

  var bgPoster = $('.section--bg');

  // if (bgPoster.length) {
  //   var random = Math.floor(Math.random() * 7) + 1;
  //   bgPoster.addClass('section-poster--base');
  //   bgPoster.addClass('section-poster--' + random);
  // }

  $('.go-top').on('click', function (e) {
    $('html, body').animate(
      {
        scrollTop: '0px',
      },
      400
    );
  });

  $('.intl').on('click', function (e) {
    e.preventDefault();
    Cookies.set('lang-3h', $(this).data('intl'), { expires: 365 });
    location.reload();
  });
})();
